import { Box, CardMedia, Typography } from "@mui/material";
import { DefaultPage } from "../../content";
import { isPair } from "../../utils/utils";
import React from "react";
import { SectionTitle } from "../atoms/SectionTitle";

interface Props {
  index: number;
  page: DefaultPage;
}

export const DefaultSection: React.FC<Props> = ({ index, page }) => {
  return (
    <Box
      aria-label={`default-section-${page.alias}`}
      sx={{
        display: { md: "flex" },
        paddingX: { xs: 4, md: 12 },
        paddingY: { xs: 4, md: 8 },
        backgroundColor: isPair(index) ? "primary.main" : "secondary.main",
        minHeight: "100vh",
        alignItems: "center",
      }}
    >
      <Box sx={{ marginRight: { md: 8 } }}>
        <SectionTitle
          title={page.title}
          color={isPair(index) ? "text.primary" : "text.secondary"}
        />

        <Box>
          {page.paragraphs.map((paragraph) => (
            <Typography
              key={paragraph}
              sx={{
                color: isPair(index) ? "text.primary" : "text.secondary",
                fontSize: "18px",
                textAlign: "justify",
                mb: 2,
              }}
            >
              {paragraph}
            </Typography>
          ))}
        </Box>
      </Box>

      <CardMedia
        component="img"
        sx={{ width: { md: "50%" }, zIndex: 10 }}
        image={page.image}
        alt={`default-section-${page.alias}-image`}
      />
    </Box>
  );
};
