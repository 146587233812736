import { Box, IconButton } from "@mui/material";
import { socialMedias } from "../../content";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import footerLogo from "../../content/images/footer-logo.png";

export default function FooterSection() {
  return (
    <Box
      sx={{
        backgroundColor: "primary.main",
        paddingX: { xs: 4, md: 16 },
        paddingY: 4,
        display: "flex",
        flexDirection: { xs: "column", md: "row-reverse" },
        justifyContent: { xs: "center", md: "space-between" },
        width: "100%",
        alignItems: "center",
      }}
    >
      <Box>
        <img src={footerLogo} className="App-logo" alt="logo" height="250px" />
      </Box>
      <Box
        sx={{
          color: "#ffffff",
        }}
      >
        <Box>
          {socialMedias.map((socialMedia, index) => (
            <IconButton
              aria-label={`social-media-${index}`}
              onClick={() => window.open(socialMedia.link, "_blank")}
              sx={{ width: 80, height: 80, color: "secondary.main" }}
            >
              {socialMedia.name === "phone" && (
                <PhoneIcon sx={{ width: 56, height: 56 }} />
              )}
              {socialMedia.name === "instagram" && (
                <InstagramIcon sx={{ width: 56, height: 56 }} />
              )}
              {socialMedia.name === "whatsapp" && (
                <WhatsAppIcon sx={{ width: 56, height: 56 }} />
              )}
              {socialMedia.name === "email" && (
                <EmailIcon sx={{ width: 56, height: 56 }} />
              )}
            </IconButton>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
