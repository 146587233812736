import { Box, Card, CardContent, Rating, Typography } from "@mui/material";
import { ReviewsPage } from "../../content";
import { isPair } from "../../utils/utils";
import React from "react";
import { SectionTitle } from "../atoms/SectionTitle";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

interface Props {
  index: number;
  page: ReviewsPage;
}

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export const ReviewsSection: React.FC<Props> = ({ index, page }) => {
  return (
    <Box
      aria-label={`reviews-section-${page.alias}`}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: { md: "center" },
        paddingX: { xs: 4, md: 12 },
        paddingY: { xs: 4, md: 8 },
        backgroundColor: isPair(index) ? "primary.main" : "secondary.main",
        height: { md: "100vh" },
      }}
    >
      <SectionTitle
        title={page.title}
        color={isPair(index) ? "text.primary" : "text.secondary"}
      />

      <Carousel responsive={responsive} autoPlay infinite>
        {page.reviews?.map((carouselItem, carouselIndex) => (
          <Card
            key={carouselIndex}
            sx={{
              display: "flex",
              flexDirection: "column",
              boxShadow: "none",
              backgroundColor: "transparent",
              mr: 2,
            }}
          >
            <CardContent
              sx={{
                backgroundColor: isPair(index)
                  ? "secondary.main"
                  : "primary.main",
                padding: 4,
                height: { md: "50vh" },
              }}
            >
              <Rating
                sx={{ mb: 4 }}
                name="read-only"
                value={carouselItem.rating}
                readOnly
              />

              <Typography
                sx={{
                  color: isPair(index) ? "text.secondary" : "text.primary",
                  fontSize: "18px",
                  textAlign: "justify",
                  marginBottom: 2,
                }}
              >
                {carouselItem.review}
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  marginBottom: 2,
                  color: isPair(index) ? "text.secondary" : "text.primary",
                }}
              >
                {carouselItem.name}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Carousel>
    </Box>
  );
};
