import whoIAmImage from "./images/who-am-i-image.jpeg";
import whoIAmImage2 from "./images/who-am-i-image-2.jpeg";
import aboutUsImage from "./images/about-us-image.jpeg";
import carouselImage1 from "./images/carousel-image-1.jpg";
import carouselImage2 from "./images/carousel-image-2.jpg";
import carouselImage3 from "./images/carousel-image-3.jpg";
import carouselImage4 from "./images/carousel-image-4.jpg";

type Clinic = {
  name: string;
  address: string;
  contact: string;
};

type CarouselItem = {
  title: string;
  paragraph: string;
  image?: string;
};

type Review = {
  name: string;
  review: string;
  rating: number;
};

export type CardPage = {
  alias: string;
  type: PageType.CARD;
  cards: {
    title: string;
    paragraphs: string[];
    image: string;
  }[];
};

export type DefaultPage = {
  alias: string;
  type: PageType.DEFAULT;
  title: string;
  paragraphs: string[];
  image?: string;
};

export type CarouselPage = {
  alias: string;
  type: PageType.CAROUSEL;
  title: string;
  paragraphs?: string[];
  carousel?: CarouselItem[];
};

export type ReviewsPage = {
  alias: string;
  type: PageType.REVIEWS;
  title: string;
  paragraphs?: string[];
  reviews?: Review[];
};

export type Page = CardPage | DefaultPage | CarouselPage | ReviewsPage;

export const landingPageTitle = "Empoderando discursos e transformando vidas";
export const landingPageDescription = "Tenha uma comunicação clara e confiante";

export const landingPageActionButtonMessage =
  "Olá!%20Gostaria%20de%20receber%20mais%20informações%20sobre%20os%20serviços%20oferecidos.";
export const landingPageActionButtonNumber = "5548996682337";
export const cellphone = "48996682337";

export enum PageType {
  CARD = "CARD",
  DEFAULT = "DEFAULT",
  CAROUSEL = "CAROUSEL",
  REVIEWS = "REVIEWS",
}

export const pages: Page[] = [
  {
    alias: "about-us",
    type: PageType.CARD,
    cards: [
      {
        title: "Lauanda Santos",
        paragraphs: [
          "Fonoaudióloga pela Universidade Federal do Rio de Janeiro (UFRJ), fez sua primeira especialização na modalidade Residência Multiprofissional em Saúde no Hospital Universitário da Universidade Federal de Santa Catarina (HU/UFSC) e Mestra pela mesma universidade. Possui cursos nas áreas de Voz e formação em Oratória pelo Instituto Rogéria Guida (Rio de Janeiro/RJ).",
          "Com anos de experiência, tem grande paixão por ajudar indivíduos a aprimorarem suas habilidades de comunicação e expressão oral. Apresenta abordagem personalizada e técnicas comprovadas que têm ajudado profissionais de diversas áreas a se destacarem em apresentações, entrevistas e discursos.",
        ],
        image: whoIAmImage,
      },
      {
        title: "Maysa Santos",
        paragraphs: [
          "Formada em Fonoaudiologia pela Universidade Federal de Santa Catarina (UFSC). A sua primeira especialização foi na programa de Residência Multiprofissional em Saúde com foco em Alta Complexidade (HU/UFSC), seguida por especializações em fala e linguagem. Realizou também formação na área de comunicação e oratória, e se tornou Mestra em Fonoaudiologia pela UFSC.",
          "Acredita na comunicação como chave para transmitir confiança e estabelecer conexões, oferecendo serviços personalizados para atender às necessidades de cada pessoa.",
        ],
        image: whoIAmImage2,
      },
    ],
  },
  {
    alias: "how-we-created",
    title: "Como surgiu a LM Comunicação",
    type: PageType.DEFAULT,
    paragraphs: [
      "A LM Comunicação foi fundada em 2024 por duas amigas, uma carioca e outra pernambucana, que se conheceram em Santa Catarina. Unidas pela profissão e paixão pela comunicação, decidiram criar uma empresa dedicada ao desenvolvimento da oratória e da comunicação verbal.",
      "Os serviços oferecidos são de alta qualidade e capacitam indivíduos a se destacarem em suas habilidades de comunicação. A empresa combina experiência clínica em fonoaudiologia com técnicas de treinamento para desenvolver programas personalizados para abordar aspectos técnicos da comunicação e acredita no poder transformador da comunicação eficaz para abrir portas para o sucesso pessoal e profissional.",
      "Todos os nossos serviços estão disponíveis online e presencialmente nas cidades de Florianópolis, Nova Trento e São João Batista, SC. ",
    ],
    image: aboutUsImage,
  },
  {
    alias: "offered-services",
    type: PageType.CAROUSEL,
    title: "Serviços oferecidos",
    carousel: [
      {
        title: "Atendimento Individualizado",
        paragraph:
          "Sessões personalizadas de oratória para melhorar a expressão oral e habilidades de comunicação. Utilizamos técnicas para trabalhar articulação, entonação, ritmo e expressividade da fala, adaptadas às necessidades de cada cliente.",
        image: carouselImage1,
      },
      {
        title: "Supervisão para Fonoaudiólogos",
        paragraph:
          "Supervisão clínica e orientação profissional para fonoaudiólogos que desejam aprimorar suas habilidades em oratória e comunicação, bem como se capacitar na área para oferecer esse tipo de atendimento. Compartilhamos nosso conhecimento especializado para ajudar colegas a desenvolver competências na área.",
        image: carouselImage2,
      },
      {
        title: "Treinamento para grupos de estudantes",
        paragraph:
          "Palestras interativas e cursos personalizados para grupos de estudantes. Focamos em aprimorar habilidades de comunicação, como técnicas de apresentação, dicção e projeção vocal. Os alunos desenvolvem competências essenciais para o sucesso acadêmico e profissional em um ambiente de aprendizado dinâmico e colaborativo.",
        image: carouselImage3,
      },
      {
        title: "Treinamento para Empresas",
        paragraph:
          "Palestras interativas e cursos presenciais personalizados para empresas, focando no desenvolvimento das habilidades de comunicação dos colaboradores. Abordamos técnicas de apresentação, gestão da ansiedade, dicção e projeção vocal, entre outros.",
        image: carouselImage4,
      },
    ],
  },
  {
    alias: "reviews",
    type: PageType.REVIEWS,
    title: "Depoimentos",
    reviews: [
      {
        name: "Adailton Martins",
        review:
          "Minha experiência com a Lauanda foi extremamente positiva, tendo resultados já nas primeiras semanas quanto a potência da voz e a articulação. Recomendo demais os serviços prestados!",
        rating: 5,
      },
      {
        name: "Larissa Salvador",
        review:
          "A Dra. Lauanda é uma excelente profissional. Foi atenciosa e acolhedora desde o primeiro momento da consulta até o final. Busquei atendimento para aprimorar a comunicação e oratória. Tivemos grandes avanços durante as sessões. Agradeço muito todo o comprometimento e atenção que teve comigo!!",
        rating: 5,
      },
      {
        name: "Maristela Januário",
        review:
          "Estou muito satisfeita com o resultado, assistir esses vídeos me deixa bem feliz. É uma mudança enorme, nem dá para acreditar. Obrigada por tudo, Maysa.",
        rating: 5,
      },
      {
        name: "Andresa Lima",
        review:
          "Obrigada pelo excelente atendimento. Você me ajudou muito, quero te agradecer por tudo.",
        rating: 5,
      },
    ],
  },
];

export const clinics: Clinic[] = [];

export const socialMedias = [
  {
    name: "phone",
    link: `tel:${cellphone}`,
  },
  {
    name: "instagram",
    link: "https://www.instagram.com/lmcomunica/",
  },
  {
    name: "whatsapp",
    link: `https://wa.me/${landingPageActionButtonNumber}?text=${landingPageActionButtonMessage}`,
  },
  {
    name: "email",
    link: "mailto:lmcomunicacao.empresa@gmail.com",
  },
];
