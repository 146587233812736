import { Divider, Typography } from "@mui/material";
import React from "react";

type Props = {
  title: string;
  color: string;
};
export const SectionTitle: React.FC<Props> = ({ title, color }) => (
  <>
    <Divider
      sx={{
        width: 100,
        borderColor: color,
        marginBottom: 2,
      }}
    />
    <Typography variant="h4" sx={{ marginBottom: 4 }} color={color}>
      {title}
    </Typography>
  </>
);
