import { Typography } from "@mui/material";
import LandingSectionLayout from "./LandingSectionLayout";
import mainBackgroundImg from "../../content/images/main-background-image.jpeg";
import { ScheduleNowButton } from "../atoms/ScheduleNowButton";
import { landingPageDescription, landingPageTitle } from "../../content";

export default function LandingSection() {
  return (
    <LandingSectionLayout
      sxBackground={{
        backgroundImage: `url(${mainBackgroundImg})`,
        backgroundColor: "#7fc7d9", // Average color of the background image.
        backgroundPosition: "center",
      }}
    >
      {/* Increase the network loading priority of the background image. */}
      <img
        style={{ display: "none" }}
        src={mainBackgroundImg}
        alt="increase priority"
      />
      <Typography variant="h3" color="white" align="center">
        {landingPageTitle}
      </Typography>
      <Typography align="center" variant="h4" sx={{ my: 4, color: "inherit" }}>
        {landingPageDescription}
      </Typography>
      <ScheduleNowButton />
    </LandingSectionLayout>
  );
}
