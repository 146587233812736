import { Box } from "@mui/material";
import { Page, pages, PageType } from "../../content";
import { useCallback } from "react";
import { CardSection } from "./CardSection";
import { DefaultSection } from "./DefaultSection";
import { CarouselSection } from "./CarouselSection";
import { ReviewsSection } from "./ReviewsSection";

export const Content = () => {
  const renderContent = useCallback((page: Page, index: number) => {
    switch (page.type) {
      case PageType.CARD:
        return <CardSection key={page.alias} index={index} page={page} />;
      case PageType.CAROUSEL:
        return <CarouselSection key={page.alias} index={index} page={page} />;
      case PageType.REVIEWS:
        return <ReviewsSection key={page.alias} index={index} page={page} />;
      default:
        return <DefaultSection key={page.alias} index={index} page={page} />;
    }
  }, []);
  return (
    <Box>
      {pages.map((page, index) => (
        <>{renderContent(page, index)}</>
      ))}
    </Box>
  );
};
