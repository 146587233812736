import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
import { CardPage } from "../../content";
import { isPair } from "../../utils/utils";
import React from "react";
import { SectionTitle } from "../atoms/SectionTitle";

interface Props {
  index: number;
  page: CardPage;
}

export const CardSection: React.FC<Props> = ({ index, page }) => {
  return (
    <Box
      aria-label={`card-section-${page.alias}`}
      sx={{
        paddingX: { xs: 4, md: 12 },
        paddingY: { xs: 4, md: 8 },
        backgroundColor: isPair(index) ? "primary.main" : "secondary.main",
      }}
    >
      {page.cards.map((card, cardIndex) => (
        <Card
          key={cardIndex}
          sx={{
            display: { xs: "block", sm: "flex" },
            boxShadow: "none",
            flexDirection: isPair(cardIndex) ? "row" : "row-reverse",
            backgroundColor: "transparent",
            mb: { xs: 4, sm: 0 },
          }}
        >
          <CardContent
            sx={{
              backgroundColor: isPair(index)
                ? "primary.main"
                : "secondary.main",
              paddingRight: { xs: 2, sm: 8 },
              paddingLeft: { xs: 2, sm: isPair(cardIndex) ? 0 : 8 },
            }}
          >
            <SectionTitle
              title={card.title}
              color={isPair(index) ? "text.primary" : "text.secondary"}
            />
            {card.paragraphs.map((paragraph) => (
              <Typography
                sx={{
                  color: isPair(index) ? "text.primary" : "text.secondary",
                  fontSize: "18px",
                  mb: 2,
                  textAlign: "justify",
                }}
              >
                {paragraph}
              </Typography>
            ))}
          </CardContent>

          <CardMedia
            component="img"
            sx={{ width: { sm: 280 }, zIndex: 10 }}
            image={card.image}
            alt="Live from space album cover"
          />
        </Card>
      ))}
    </Box>
  );
};
