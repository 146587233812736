import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
import { CarouselPage } from "../../content";
import { isPair } from "../../utils/utils";
import React from "react";
import { SectionTitle } from "../atoms/SectionTitle";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

interface Props {
  index: number;
  page: CarouselPage;
}

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export const CarouselSection: React.FC<Props> = ({ index, page }) => {
  return (
    <Box
      aria-label={`carousel-section-${page.alias}`}
      sx={{
        paddingX: { xs: 4, md: 12 },
        paddingY: { xs: 4, md: 8 },
        backgroundColor: isPair(index) ? "primary.main" : "secondary.main",
        height: { md: "100vh" },
      }}
    >
      <SectionTitle
        title={page.title}
        color={isPair(index) ? "text.primary" : "text.secondary"}
      />

      <Carousel responsive={responsive} autoPlay infinite>
        {page.carousel?.map((carouselItem, carouselIndex) => (
          <Card
            key={carouselIndex}
            sx={{
              display: "flex",
              flexDirection: "column",
              boxShadow: "none",
              backgroundColor: "transparent",
              mr: 2,
            }}
          >
            <CardMedia
              component="img"
              sx={{ height: "40vh" }}
              image={carouselItem.image}
              alt={`carousel-section-${page.alias}-image-${carouselIndex}`}
            />
            <CardContent
              sx={{
                backgroundColor: isPair(index)
                  ? "secondary.main"
                  : "primary.main",
                padding: 4,
                height: { md: "35vh" },
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  marginBottom: 2,
                  color: isPair(index) ? "text.secondary" : "text.primary",
                }}
              >
                {carouselItem.title}
              </Typography>
              <Typography
                sx={{
                  color: isPair(index) ? "text.secondary" : "text.primary",
                  fontSize: "18px",
                  textAlign: "justify",
                }}
              >
                {carouselItem.paragraph}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Carousel>
    </Box>
  );
};
