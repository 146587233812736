import React from "react";
import "./App.css";
import FooterSection from "./components/molecules/FooterSection";
import LandingSection from "./components/molecules/LandingSection";
import withRoot from "./withRoot";
import { Content } from "./components/molecules/Content";

function App() {
  return (
    <React.Fragment>
      <LandingSection />
      <Content />
      <FooterSection />
    </React.Fragment>
  );
}

export default withRoot(App);
